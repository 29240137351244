import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { IconArrowRight } from "../icons/Icons"

const HowItWorks = ({ className, heading = "How it works", boxClassName, arrowClassName }) => {
  return (
    <section className={className || "w-full p-10 visibility-auto"}>
      <h2 className={"py-10 text-center text-4xl uppercase"}>{heading}</h2>
      <div className={"how-it-works flex flex-wrap items-center justify-center gap-4 px-4"}>
        <Link
          to="/what-are-you-looking-to-sell/"
          className={
            boxClassName || "hoverScale flex-grow-0 text-center md:w-2/5 lg:w-auto lg:flex-1"
          }
        >
          <StaticImage src={"../../images/homepage/value.png"} alt={"Valuation"} />
          <h3>Request a Quote</h3>
          <p>
            Find out how much is your watch or jewelry worth? The more information & photos you
            share with us the more accurate the market valuation will be.
          </p>
        </Link>
        <div className={"hidden flex-shrink self-center md:block"}>
          <IconArrowRight className={"text-accent"} />
        </div>
        <Link
          to="/ez-ship-box-request-form/"
          className={
            boxClassName || "hoverScale flex-grow-0 text-center md:w-2/5 lg:w-auto lg:flex-1"
          }
        >
          <StaticImage src={"../../images/homepage/shipping.png"} alt={"Shipping"} />
          <h3>Send Us Your Jewelry</h3>
          <p>
            We will provide Easy Ship box and packing materials with our address label. Simply take
            it to the post office. Send it via <b>registered mail and fully insured*</b>, and we
            will refund your cost of shipping and insurance upon purchase.
            <br />
            <em className={"block my-4"}>
              * Be sure to protect your valuables with adequate shipping insurance
            </em>
          </p>
        </Link>
        <div className={arrowClassName || "hidden flex-shrink self-center lg:block"}>
          <IconArrowRight className={"text-accent"} />
        </div>
        <div
          className={
            boxClassName ||
            "hoverScale flex-grow-0 text-link text-center md:w-2/5 lg:w-auto lg:flex-1"
          }
        >
          <StaticImage src={"../../images/homepage/inspection.png"} alt={"Inspection"} />
          <h3>Full Jewelry Inspection</h3>
          <p>
            Once we receive your jewelry our qualified experts will inspect its condition and
            authenticity.
          </p>
        </div>
        <div className={"hidden flex-shrink self-center md:block"}>
          <IconArrowRight className={"text-accent"} />
        </div>
        <div
          className={
            boxClassName ||
            "hoverScale flex-grow-0 text-link text-center md:w-2/5 lg:w-auto lg:flex-1"
          }
        >
          <StaticImage src={"../../images/homepage/payment.png"} alt={"Payment"} />
          <h3>Receive Payment</h3>
          <p>Once finalized you will quickly receive payment via check or wire payment.</p>
        </div>
      </div>
    </section>
  )
}

export default HowItWorks
