import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const AdditionalForms = ({
  // className = "w-10/12 mx-auto",
  headerClassName = "p-5 text-center text-3xl uppercase col-span-1 md:col-span-2 xl:col-span-4",
  gridClassName = "w-full max-w-max mx-auto grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 px-5 py-5 2xl:px-0 ",
  watches = "What is my Rolex, Cartier, Patek Philippe, Audemars Piguet watch worth?",
  jewelry = "What is my Cartier, Tiffany & Co., Van Cleef & Arpels, David Yurman jewelry worth?",
  diamonds = "Certified Diamonds 1ct and above? What is my GIA Certified diamond worth?",
  silver = "What is my sterling silver bullion coins, flatware, full tea set or hollowware worth?",
  prefix = <nobr>Sell your</nobr>,
  // buttonClassName = "transform rounded-md bg-gray-500 text-center text-base font-semibold text-white duration-300  hover:bg-gray-600 hover:tracking-wider py-5 justify-end",
}) => {
  const QuoteCard = ({ image, title, description, buttonText, link }) => {
    return (
      <Link
        to={link}
        className="grid group bg-white mx-auto rounded-lg border border-accentMd shadow-md w-full p-5 max-w-[350px] grid-rows-[auto_auto_1fr_auto] gap-2 items-center justify-center group"
      >
        {image}

        <div className="bg-[#816230] text-sm group-hover:bg-accentMd duration-200 text-white mx-auto max-w-max px-4 py-2 rounded-full">
          {buttonText}
        </div>
        <h3 className=" text-center text-xl text-gray-600 grow uppercase">{title}</h3>
        <p className="flex justify-start h-full ">{description}</p>
      </Link>
    )
  }

  return (
    <section className={gridClassName}>
      <h2 className={headerClassName}>What Are You Looking To Sell?</h2>
      <QuoteCard
        image={
          <StaticImage
            placeholder="blurred"
            loading="lazy"
            src={"../../images/watch-quote-btn.jpeg"}
            alt={"The solution to selling your jewelry"}
            className={"m-auto transform duration-200 group-hover:-translate-y-2"}
          />
        }
        title={<>{prefix} watch</>}
        description={watches}
        buttonText={"GET WATCH QUOTE"}
        link={"/what-is-my-watch-worth/"}
      />
      <QuoteCard
        image={
          <StaticImage
            placeholder="blurred"
            loading="lazy"
            src={"../../images/jewelry-quote-btn.jpeg"}
            alt={"The solution to selling your luxury watches"}
            className={" transform duration-200 hover:-translate-y-2"}
          />
        }
        title={<>{prefix} jewelry</>}
        description={jewelry}
        buttonText={" GET JEWELRY QUOTE"}
        link={"/what-is-my-jewelry-worth/"}
      />
      <QuoteCard
        image={
          <StaticImage
            placeholder="blurred"
            loading="lazy"
            src={"../../images/diamond-quote-btn.webp"}
            alt={"The solution to selling your diamonds"}
            className={" transform duration-200 hover:-translate-y-2"}
          />
        }
        title={<>{prefix} diamond</>}
        description={diamonds}
        buttonText={" GET DIAMOND QUOTE"}
        link={"/what-is-my-diamond-worth/"}
      />
      <QuoteCard
        image={
          <StaticImage
            placeholder="blurred"
            loading="lazy"
            src={"../../images/sterling-silver-quote-btn.jpeg"}
            alt={"The solution to selling your sterling silver"}
            className={"transform duration-200 hover:-translate-y-2"}
          />
        }
        title={
          <>
            {prefix} <nobr>sterling silver</nobr>
          </>
        }
        description={silver}
        buttonText={" GET SILVER QUOTE"}
        link={"/what-is-my-sterling-silver-worth/"}
      />
    </section>
  )
}

export default AdditionalForms
